.evaluationPdf .pageHeader {
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.evaluationPdf .pageTitle {
    width: 200px;
    text-align: center;
}

.evaluationPdf .pageWrapper {
    width: 816px;
    padding: 80px 20px 15px 20px;
}

.evaluationPdf .nameTagContainer {
    padding-top: 50px;
    width: 816px;
    display:flex;
    justify-content: space-between;
}

.evaluationPdf .pageWrapper table{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px;
}

.evaluationPdf .pageWrapper th{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px;
}

.evaluationPdf .pageWrapper td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px;
}

.evaluationPdf .noMargin {
    margin: 0px;
}

.evaluationPdf .requirementRowStyle td {
    border: none;
}