.byTermPdf .pageHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.byTermPdf .pageWrapper {
    width: 816px;
    font-size: 10px;
}

.byTermPdf .nameTagContainer {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.byTermPdf .pageWrapper table{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px;
}

.byTermPdf .pageWrapper th{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px;
}

.byTermPdf .pageWrapper td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 2px;
}

.byTermPdf .noMargin {
    margin: 0px;
}