body {
  margin: 0px;
}
.container {
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  flex-flow: nowrap column;
  overflow: hidden;
}

.panel {
  display: flex;
}

.body {
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
}

.sideBarResizeContainer {
  width: 30%;
}
.sidebar {
  flex-grow: 1;
}

.withMargin {
  margin: 10px;
  box-sizing: border-box;
}

.content {
  border-right: 1px solid #ccc;
  flex-grow: 2;
  background: white;
}

.customResizeBorder {
  cursor: ew-resize;
  display: flex;
  z-index: 99999;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: visible;
}
