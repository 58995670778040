.d-tree-container {
  list-style: none;
  padding: 0;
}

.d-tree-content .d-tree-node {
  padding-left: 20px;
}

.d-tree-node:hover {
  cursor: pointer;
}

.d-tree-action {
  visibility: hidden;
}

.d-tree-head:hover > .d-tree-action {
  visibility: visible;
}

.d-tree-node {
  width: fit-content;
}
